import Papa from 'papaparse';
import React, { FC, useContext, useRef } from 'react';
import { useMutation } from 'react-query';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { BulkOrderingType } from '../../../models/bulk-ordering-type';
import FormErrorTypeEnum from '../../../models/form-error-type-enum';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import ProductTypeEnum from '../../../models/product-type-enum';
import ButtonSecondary from '../../atoms/button-secondary/button-secondary';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import LoadingSpinner from '../../atoms/loading-spinner/loading-spinner';
import TextLink from '../../atoms/text-link/text-link';
import AlertModal from '../../molecules/alert-modal/alert-modal';
import Modal from '../../molecules/modal/modal';
import RichText from '../../molecules/rich-text/rich-text';
import useAddMultipleToBasket from '../../providers/basket/hooks/use-add-multiple-to-basket';
import open from '../../providers/overlay/open.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';

const BulkOrdering: FC<BulkOrderingType> = ({
  addProductErrorHeading,
  addProductErrorDescription,
  addProductErrorBody,
  insufficientClearanceStockError,
  clearQuoteAccept,
  clearQuoteCancel,
  clearQuoteDescription,
  clearQuoteHeading,
  clearQuoteHelp,
  description,
  downloadTemplateLink,
  downloadTemplateLinkText,
  heading,
  popupDescription,
  popupHeading,
  readMoreLink,
  uploadCsvButtonText,
  onUploadComplete,
  compact,
}: BulkOrderingType) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const addMultipleToBasket = useAddMultipleToBasket(
    {
      addProductErrorHeading,
      addProductErrorDescription,
      addProductErrorBody,
      insufficientClearanceStockError,
    },
    {
      clearQuoteAccept,
      clearQuoteCancel,
      clearQuoteDescription,
      clearQuoteHeading,
      clearQuoteHelp,
    }
  );
  const fileInputRef = useRef(null);

  const openFileBrowser = (): void => {
    fileInputRef.current.click();

    pushToDataLayer(GtmEventNameEnum.BulkUpload, {
      productType: ProductTypeEnum.Website,
    });
  };

  const { mutate: handleFileUpload, isLoading } = useMutation(
    async (target: HTMLInputElement): Promise<void> => {
      if (!target.files.length) {
        return;
      }

      const csv = target.files[0] as File;
      const data = Papa.parse<[string, string]>(await csv.text(), {
        skipEmptyLines: true,
      });

      // eslint-disable-next-line no-param-reassign
      target.value = '';

      if (data.errors?.length > 0) {
        overlayDispatch(
          open(
            <AlertModal
              heading="Upload Error"
              message={FormErrorTypeEnum.Unknown}
            />
          )
        );
        return;
      }

      if (data.data?.length === 0) {
        overlayDispatch(
          open(
            <AlertModal
              heading="Upload Error"
              message="The CSV must be at least 1 row."
            />
          )
        );
        return;
      }

      if (data.data?.length > 500) {
        overlayDispatch(
          open(
            <AlertModal
              heading="Upload Error"
              message="The CSV must contain no more than 500 rows."
            />
          )
        );
        return;
      }

      if (!data.data.every((row) => row[0] && row[1])) {
        overlayDispatch(
          open(
            <AlertModal
              heading="Upload Error"
              message="Each of the CSVs rows must have two columns."
            />
          )
        );
        return;
      }

      if (!data.data.every((row) => !Number.isNaN(row[1]))) {
        overlayDispatch(
          open(
            <AlertModal
              heading="Upload Error"
              message="Each quantity must be numeric."
            />
          )
        );
        return;
      }

      const res = await addMultipleToBasket(
        data.data.map((item) => ({
          variantSku: item[0],
          quantity: Number(item[1]),
        }))
      );

      if (!res?.lineItemIssues?.length && onUploadComplete) {
        onUploadComplete();
      }
    }
  );

  return (
    <div className="h-full py-component-spacing-mobile lg:py-component-spacing xl:pb-0 xl:pt-0">
      <div className="bg-floral flex flex-col h-full justify-around p-6 rounded-lg">
        {!compact && (
          <div>
            <h5 className="page-heading-five mb-2">{heading}</h5>

            <RichText className="mr-2">{description}</RichText>

            <button
              onClick={() => {
                overlayDispatch(
                  open(
                    <Modal medium>
                      <div className="space-y-4">
                        <h4 className="page-heading-four">{popupHeading}</h4>

                        <RichText>{popupDescription}</RichText>
                      </div>
                    </Modal>
                  )
                );

                pushToDataLayer(GtmEventNameEnum.DashboardBulkLink, {
                  productType: ProductTypeEnum.Website,
                });
              }}
              type="button"
            >
              <TextLink
                className="inline"
                showIcon={false}
                name={readMoreLink}
              />
            </button>
          </div>
        )}

        <div
          className={`flex items-center ${
            !compact ? 'flex-col mt-6' : 'flex-row justify-center'
          }`}
        >
          <form>
            <input
              accept=".csv"
              ref={fileInputRef}
              onChange={(event) => handleFileUpload(event.target)}
              type="file"
              hidden
            />

            {!isLoading && (
              <ButtonSecondary
                name={uploadCsvButtonText}
                onClick={() => openFileBrowser()}
              />
            )}
            {isLoading && <LoadingSpinner className="h-12" />}
          </form>

          {downloadTemplateLink && (
            <div
              className={`flex items-center text-center ${
                !compact ? 'mt-4' : 'ml-6'
              }`}
            >
              <TextLink
                name={
                  <div className="flex items-center space-x-2">
                    <IcomoonIcon
                      color="french-blue"
                      name="download"
                      size="sm"
                    />
                    <span className="font-semibold">
                      {downloadTemplateLinkText}
                    </span>
                  </div>
                }
                onClick={() => {
                  pushToDataLayer(GtmEventNameEnum.BulkTemplate, {
                    productType: ProductTypeEnum.Website,
                  });
                }}
                url={downloadTemplateLink}
                download
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BulkOrdering.defaultProps = {
  onUploadComplete: null,
  compact: false,
};

export default BulkOrdering;
